<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">キャスト（プロフィール）ページの編集</h1>
        <banner-hint>
          システム標準のコンテンツパーツ他、登録した画像バナー＆ウィジェットパーツをキャストのプロフィールページの好きな場所に表示することができます。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="mb-5 py-7 px-6"
          elevation="1"
        >
          <v-row>
            <v-col cols="12"
              class="pb-0"
            >
              <num-instruction number="1">パーツ一覧からプロフィール画面の左側、右側と下段に表示するパーツを選びます。</num-instruction>
            </v-col>
            <v-col cols="5">
              <v-select
                class="mx-2 pt-0"
                v-model="selectedCompsL"
                chips
                label="左側パーツ"
                multiple
                :items="profileComps"
                item-value="component_name"
                item-text="original_name"
                item-color="primary"
              ></v-select>
            </v-col>
            <v-col cols="7">
              <v-select
                class="mx-2 pt-0"
                v-model="selectedCompsR"
                chips
                label="右側パーツ"
                multiple
                :items="profileComps"
                item-value="component_name"
                item-text="original_name"
                item-color="primary"
              ></v-select>
            </v-col>
            <v-col cols="12"
              class="pt-0"
            >
              <v-select
                class="mx-2 pt-0"
                v-model="selectedCompsB"
                chips
                label="画面下段パーツ"
                multiple
                :items="profileComps"
                item-value="component_name"
                item-text="original_name"
                item-color="primary"
              ></v-select>
            </v-col>
          </v-row>

          <num-instruction number="2">↓のプレビューを確認しながら必要に応じてパーツ名（タイトル）の編集、パーツの並べ替えをします。</num-instruction>

          <v-form ref="form">
            <icon-info icon="lightbulb-on-outline" class="mt-3 mb-7 mx-1" :square="true">
              並べ替えは<v-icon small>mdi-gesture-double-tap</v-icon>をドラッグ＆ドロップで。<br />
              表示名を空欄にするとHP上で見出しが消えます。
            </icon-info>

            <v-row>
              <!-- 左列 -->
              <v-col cols="5">
                <v-card
                  class="pannel mb-5 d-flex align-center justify-center grey"
                  height="600"
                >
                  <p>
                    <v-icon>mdi-face-woman-outline</v-icon>
                    パネル画像
                  </p>
                </v-card>

                <draggable
                  v-model="selectedCompsL"
                  group="comps"
                  handle=".draggable"
                  animation="100"
                  delay="0"
                >
                  <v-card class="component mb-5 pt-5 pb-3 px-5"
                    v-for="(component, index) in selectedCompsLObj"
                    :key="component.component_name"
                  >
                    <v-row>
                      <!-- 表示名 -->
                      <v-col cols="9">
                        <v-text-field
                          v-model.trim="component.display_name"
                          required
                          placeholder="HP上の表示名"
                          :hint="`パーツ名：${component.original_name}`"
                          persistent-hint
                          counter="20"
                          :rules="[valiRules.max20]"
                        ></v-text-field>
                      </v-col>
                      <!-- アイコン＆バッジ -->
                      <v-col cols="3"
                        class="draggable d-flex justify-end align-self-center"
                        align="rignt"
                      >
                        <v-badge
                          class=""
                          :content="index + 1"
                          overlap
                          color="accent"
                        >
                          <v-avatar
                            color="primary"
                            size="40"
                          >
                            <v-icon>
                              mdi-gesture-double-tap
                            </v-icon>
                          </v-avatar>
                        </v-badge>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <!-- 色指定 -->
                      <v-col cols="9">
                        <v-select
                          v-model="component.bg_color"
                          :items="bgColorList"
                          item-text="label"
                          item-value="value"
                          color="primary"
                          label="背景色"
                        ></v-select>
                      </v-col>
                      <!-- ボタン -->
                      <v-col cols="3" class="d-flex justify-end align-self-end">
                        <v-btn
                          text depressed small
                          color="primary"
                          @click="deleteACompL(index)"
                        >削除</v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </draggable>
              </v-col>

              <!-- 右列 -->
              <v-col cols="7">
                <v-card
                  class="mb-5"
                  flat
                  color="transparent"
                >
                  <h3 class="py-3 mb-1 pl-3 primary font-weight-bold">キャッチコピー</h3>
                  <v-sheet class="px-4 py-5" color="secondary">
                    <div class="d-flex align-center">
                      <v-card-title class="mr-4 pa-0 font-weight-bold">キャスト名 (20)</v-card-title>
                      <v-spacer></v-spacer>
                      <v-card flat height="30" color="accent">
                        <v-card-text class="pa-0 px-2 text-overline font-weight-bold">本日 12:00〜22:00</v-card-text>
                      </v-card>
                    </div>

                    <h4 class="mt-2 pa-0">T155cm B85(D) W58 H85</h4>

                    <v-list class="px-0 pt-5" color="transparent">
                      <v-chip class="mr-2 px-4 font-weight-bold" color="accent">巨乳</v-chip>
                      <v-chip class="mr-2 px-4 font-weight-bold" color="accent">パイパン</v-chip>
                      <v-chip class="mr-2 px-4 font-weight-bold" color="accent">清楚系</v-chip>
                    </v-list>

                    <v-chip-group class="pa-0" color="transparent">
                      <v-chip class="ma-0 mr-2 px-3 font-weight-bold" color="accent" label outlined>
                        <v-icon class="mr-1">mdi-twitter</v-icon>ツイッター
                      </v-chip>
                      <v-chip class="ma-0 mr-2 px-3 font-weight-bold" color="accent" label outlined>
                        <v-icon class="mr-1">mdi-twitter</v-icon>LINE
                      </v-chip>
                    </v-chip-group>
                  </v-sheet>
                </v-card>

                <draggable
                  v-model="selectedCompsR"
                  group="comps"
                  handle=".draggable"
                  animation="100"
                  delay="0"
                >
                  <v-card class="component mb-5 py-3 px-5"
                    v-for="(component, index) in selectedCompsRObj"
                    :key="component.component_name"
                  >
                    <v-row>
                      <!-- 表示名 -->
                      <v-col cols="9">
                        <v-text-field
                          v-model.trim="component.display_name"
                          required
                          placeholder="HP上の表示名"
                          :hint="`パーツ名：${component.original_name}`"
                          persistent-hint
                          counter="20"
                          :rules="[valiRules.max20]"
                        ></v-text-field>
                      </v-col>
                      <!-- アイコン＆バッジ -->
                      <v-col cols="3"
                        class="draggable d-flex justify-end align-self-center"
                        align="rignt"
                      >
                        <v-badge
                          class=""
                          :content="index + 1"
                          overlap
                          color="accent"
                        >
                          <v-avatar
                            color="primary"
                            size="40"
                          >
                            <v-icon>
                              mdi-gesture-double-tap
                            </v-icon>
                          </v-avatar>
                        </v-badge>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <!-- 色指定 -->
                      <v-col cols="9">
                        <v-select
                          v-model="component.bg_color"
                          :items="bgColorList"
                          item-text="label"
                          item-value="value"
                          color="primary"
                          label="背景色"
                        ></v-select>
                      </v-col>
                      <!-- ボタン -->
                      <v-col cols="3" class="d-flex justify-end align-self-end">
                        <v-btn
                          text depressed small
                          color="primary"
                          @click="deleteACompR(index)"
                        >削除</v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </draggable>
              </v-col>

              <!-- 下段 -->
              <v-col cols="12">
                <draggable
                  v-model="selectedCompsB"
                  group="comps"
                  handle=".draggable"
                  animation="100"
                  delay="0"
                >
                  <v-card class="component mb-5 pt-5 pb-3 px-5"
                    v-for="(component, index) in selectedCompsBObj"
                    :key="component.component_name"
                  >
                    <v-row>
                      <!-- 表示名 -->
                      <v-col cols="9">
                        <v-text-field
                          v-model.trim="component.display_name"
                          required
                          placeholder="HP上の表示名"
                          :hint="`パーツ名：${component.original_name}`"
                          persistent-hint
                          counter="20"
                          :rules="[valiRules.max20]"
                        ></v-text-field>
                      </v-col>
                      <!-- アイコン＆バッジ -->
                      <v-col cols="3"
                        class="draggable d-flex justify-end align-self-center"
                        align="rignt"
                      >
                        <v-badge
                          class=""
                          :content="index + 1"
                          overlap
                          color="accent"
                        >
                          <v-avatar
                            color="primary"
                            size="40"
                          >
                            <v-icon>
                              mdi-gesture-double-tap
                            </v-icon>
                          </v-avatar>
                        </v-badge>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <!-- 色指定 -->
                      <v-col cols="9">
                        <v-select
                          v-model="component.bg_color"
                          :items="bgColorList"
                          item-text="label"
                          item-value="value"
                          color="primary"
                          label="背景色"
                        ></v-select>
                      </v-col>
                      <!-- ボタン -->
                      <v-col cols="3" class="d-flex justify-end align-self-end">
                        <v-btn
                          text depressed small
                          color="primary"
                          @click="deleteACompB(index)"
                        >削除</v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </draggable>
              </v-col>
            </v-row>
          </v-form>

          <num-instruction number="3">変更したら登録をお忘れなく。</num-instruction>

          <!-- 登録ボタン -->
          <div class="mt-3 ml-5">
            <v-btn
              depressed
              color="primary"
              @click="updateComps()"
            >変更を登録</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import draggable from "vuedraggable";
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import NumberInstruction from "@/components/_NumberInstruction.vue";
import IconInfo from "@/components/_IconInfo.vue";
import BannerHint from "@/components/_BannerHint.vue";

export default {
  components: {
    draggable,
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
    'num-instruction': NumberInstruction,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      profileComps: [],
      selectedCompsL: [],
      selectedCompsR: [],
      selectedCompsB: [],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      bgColorList: [
        {label: 'サブカラー', value: 'secondary'},
        {label: 'メインカラー', value: 'primary'},
        {label: '透明', value: 'transparent'},
        {label: '白', value: 'white'},
        {label: '黒', value: 'black'},
      ],
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    selectedCompsLObj() {
      const components = []
      //選択されたID配列からメニューのオブジェクト配列を作成
      this.selectedCompsL.map( compName => {
        this.profileComps.map( comp => {
          if (compName === comp.component_name) components.push(comp)
        })
      })
      return components
    },
    selectedCompsRObj() {
      const components = []
      //選択されたID配列からメニューのオブジェクト配列を作成
      this.selectedCompsR.map( compName => {
        this.profileComps.map( comp => {
          if (compName === comp.component_name) components.push(comp)
        })
      })
      return components
    },
    selectedCompsBObj() {
      const components = []
      //選択されたID配列からメニューのオブジェクト配列を作成
      this.selectedCompsB.map( compName => {
        this.profileComps.map( comp => {
          if (compName === comp.component_name) components.push(comp)
        })
      })
      return components
    },
  },

  watch: {
    selectedCompsL: function(now, old) {
      if (now.length > old.length) {
        const iR = this.selectedCompsR.findIndex( compName => compName === now[now.length -1] )
        const iB = this.selectedCompsB.findIndex( compName => compName === now[now.length -1] )
        if (iR !== -1 || iB !== -1) {
          this.selectedCompsL = [...old]
          this.snackbar = {...{color:'info', message: "そのパーツは既に使用されています", open: true}}
        }
      }
    },
    selectedCompsR: function(now, old) {
      if (now.length > old.length) {
        const iL = this.selectedCompsL.findIndex( compName => compName === now[now.length -1] )
        const iB = this.selectedCompsB.findIndex( compName => compName === now[now.length -1] )
        if (iL !== -1 || iB !== -1) {
          this.selectedCompsR = [...old]
          this.snackbar = {...{color:'info', message: "そのパーツは既に使用されています", open: true}}
        }
      }
    },
    selectedCompsB: function(now, old) {
      if (now.length > old.length) {
        const iL = this.selectedCompsL.findIndex( compName => compName === now[now.length -1] )
        const iR = this.selectedCompsR.findIndex( compName => compName === now[now.length -1] )
        if (iR !== -1 || iL !== -1) {
          this.selectedCompsB = [...old]
          this.snackbar = {...{color:'info', message: "そのパーツは既に使用されています", open: true}}
        }
      }
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await this.getCompMaster()

      await Promise.all([
        this.getSiteBanner(),
        this.getSiteWidget(),
      ])

      await this.getSiteProfileComps()

      this.loading = false
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // コンポーネントリスト取得
    //**************************************************
    getCompMaster() {
      return this.adminApi.getReqWithAuth('site-profile-component-master/').then( results => {
        if (!results || !results.length) {
          alert('パーツデータの取得に失敗しました')
          return
        }

        this.profileComps = results.filter( component => {
          component.original_name = component.display_name
          component.bg_color = 'secondary'
          return component.component_name !== 'image-banner' && component.component_name !== 'widget-tag'
        })
      })
    },

    //
    // API req: GET banner
    //
    getSiteBanner() {
      return this.adminApi.getReqWithAuth('site-banner/').then( records => {
        if (!records || !records.length) return

        records.map( banner => {
          this.profileComps.push({
            component_name: 'image-banner_' + banner.banner_id,
            original_name: banner.banner_name + '（バナー）',
            display_name: banner.banner_name,
            bg_color: 'secondary'
          })
        })
      })
    },

    //
    // API req: GET widget
    //
    getSiteWidget() {
      return this.adminApi.getReqWithAuth('site-widget/').then( records => {
        if (!records || !records.length) return

        records.map( widget => {
          this.profileComps.push({
            component_name: 'widget-tag_' + widget.widget_id,
            original_name: widget.widget_name + '（ウィジェット）',
            display_name: widget.widget_name,
            bg_color: 'secondary'
          })
        })
      })
    },

    //**************************************************
    // API req: GET component
    //**************************************************
    getSiteProfileComps() {
      return this.adminApi.getReqWithAuth('site-profile-component/').then( results => {
        if (!results || !results.length) return

        results.map( component => {
          this.profileComps.map( master => {
            // マスターに代入
            if (master.component_name === component.component_name) {
              master.display_name = component.display_name
              master.bg_color = component.bg_color
              // selected配列も作成
              if (component.placement === 'left') {
                this.selectedCompsL.push(master.component_name)
              }
              else if (component.placement === 'right') {
                this.selectedCompsR.push(master.component_name)
              }
              else if (component.placement === 'bottom') {
                this.selectedCompsB.push(master.component_name)
              }
            }
          })
        })
      })
    },

    //**************************************************
    //更新
    //**************************************************
    updateComps() {
      if (!this.selectedCompsLObj.length || !this.selectedCompsRObj.length ||
          !this.selectedCompsBObj.length || !this.$refs.form.validate()
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const jointCompArray = []
      this.selectedCompsLObj.map( comp => jointCompArray.push({ ...comp, placement: 'left' }) )
      this.selectedCompsRObj.map( comp => jointCompArray.push({ ...comp, placement: 'right' }) )
      this.selectedCompsBObj.map( comp => jointCompArray.push({ ...comp, placement: 'bottom' }) )

      const payload = JSON.stringify(jointCompArray)

      this.adminApi.apiReqWithData('POST', 'update/site-profile-component/', payload).then(() => {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //削除
    //**************************************************
    deleteACompL(index) {
      this.selectedCompsL.splice(index, 1)

      this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoDontForgetToApply, open: true}}
    },
    deleteACompR(index) {
      this.selectedCompsR.splice(index, 1)

      this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoDontForgetToApply, open: true}}
    },
    deleteACompB(index) {
      this.selectedCompsB.splice(index, 1)

      this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoDontForgetToApply, open: true}}
    },
  }
}
</script>

<style scoped>
.theme--light.component {
  background-color: var(--content-bg-diff);
}
.theme--dark.component {
  background-color: var(--content-bg-dark-diff);
}
</style>
